import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, contact1, contact2, contact3, contact4, dataTerminal, hero, logo, pulQutulari } from '../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: pulQutulari,
    name: "Bio-L birtərəfli",
    path: "/products/1",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: allInOne,
    name: "Bio-L birtərəfli",
    path: "/products/2",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: barkodProcessor,
    name: "Bio-L birtərəfli",
    path: "/products/3",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },

  {
    image: cardReader,
    name: "Bio-L birtərəfli",
    path: "/products/4",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  }

];

const Contact = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <>
    <div className="mt-[40px] max-w-container mx-auto">
         <div className='flex flex-col justify-center items-center mb-5'>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">Əlaqə</button>
         <p className="text-black text-[32px] font-medium text-center">Bizimlə əlaqə saxlayın</p>
         <p className='text-center text-black text-lg font-normal'>Köməyə ehtiyacınız var? Sual və təkliflərinizi bizə söyləyə bilərsiniz.</p>
         </div>
      <div>
 <div className='flex mt-[40px] mb-[132px] justify-between'>
 <div className="w-[383px] h-[382px] px-8 py-5 bg-[#f5f9ee] rounded-2xl justify-start items-center gap-2.5 inline-flex">
    <div className="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
        <img className="w-[143px] h-[90px]" src={logo} />
        <div className="self-stretch h-[232px] flex-col justify-start items-start gap-5 flex">
            <div className="justify-start items-center gap-5 inline-flex">
                {/* <div className="w-10 h-10 p-2.5 bg-[#b0d896] rounded-[50px] justify-center items-center gap-2.5 flex" /> */}
                <div className="w-10 h-10 bg-[#b0d896] rounded-[50px] justify-center items-center flex">
                    <img className='w-[40px] h-[40px]' src={contact1}></img>
                </div>
                <div className="w-[153px] flex-col justify-start items-start gap-1 inline-flex">
                    <div className="self-stretch text-black text-base font-medium font-['DM Sans']">Əlaqə nömrəsi</div>
                    <div className="self-stretch text-[#bcbcbc] text-sm font-normal font-['DM Sans']">+994 50 340 70 80</div>
                </div>
            </div>
            <div className="justify-start items-center gap-5 inline-flex">
            <div className="w-10 h-10 bg-[#b0d896] rounded-[50px] justify-center items-center flex">
                    <img className='w-[25px] h-[25px]' src={contact2}></img>
                </div>
                <div className="w-[153px] flex-col justify-start items-start gap-1 inline-flex">
                    <div className="self-stretch text-black text-base font-medium font-['DM Sans']">Qaynar xətt</div>
                    <div className="self-stretch text-[#bcbcbc] text-sm font-normal font-['DM Sans']">*7080</div>
                </div>
            </div>
            <div className="self-stretch justify-start items-center gap-5 inline-flex">
            <div className="w-10 h-10 bg-[#b0d896] rounded-[50px] justify-center items-center flex">
                    <img className='w-[25px] h-[25px]' src={contact3}></img>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                    <div className="self-stretch text-black text-base font-medium font-['DM Sans']">E-poçta adresi</div>
                    <div className="self-stretch text-[#bcbcbc] text-sm font-normal font-['DM Sans']">info@agrogurama.az</div>
                </div>
            </div>
            <div className="self-stretch justify-start items-center gap-5 inline-flex">
            <div className="w-10 h-10 bg-[#b0d896] rounded-[50px] justify-center items-center flex">
                    <img className='w-[25px] h-[25px]' src={contact4}></img>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                    <div className="self-stretch text-black text-base font-medium font-['DM Sans']">Ünvan</div>
                    <div className="self-stretch text-[#bcbcbc] text-sm font-normal font-['DM Sans']">Azaro plaza, Təbriz küçəsi 122</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div className='w-[794px]'>
<form class="space-y-8">
    <div class="grid grid-cols-1 gap-6"> 
      <div>
        <label for="company" class="block text-base font-medium text-[black]">Ad, soyad</label>
        <input type="text" id="company" class="bg-neutral-100 mt-2 block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3" />

      </div>
    </div>
    
    <div class="grid grid-cols-2 md:grid-cols-2 gap-6"> 
      <div>
        <label for="quantity" class="block text-base font-medium text-[black]">Əlaqə nömrəsi</label>
        <input type="number" id="quantity" class="bg-neutral-100 mt-2  block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3 "></input>
      </div>
      <div>
        <label for="email" class="block text-base font-medium text-[black]">E-mail</label>
        <input type="email" id="email" class="bg-neutral-100 mt-2 block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3 " ></input>
      </div>
    </div>

    <div>
      <label for="message" class="block text-base font-medium text-[black]">Mesajınız</label>
      <textarea id="message" rows="4" class="bg-neutral-100 h-36 pl-3 pr-3 pt-3 mt-2 block w-full border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" ></textarea>
    </div>

    <div class="flex justify-start">
      <button type="submit" class="w-[148px] h-10 p-2.5 bg-[#0d4b2c] rounded-xl justify-center items-center gap-2.5 inline-flex text-[white]">Göndər</button>
    </div>
  </form>
 </div>
 </div>
  </div>
  </div>
   </>
  );
};

export default Contact;