import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, mpost } from "../../assets/index";
import { useTranslation, initReactI18next } from "react-i18next";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";




const languageOptions = [
  { value: "en", label: "EN", flag: engflag },
  { value: "az", label: "AZ", flag: azflag },
  { value: "rus", label: "RU", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});


const Header = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);

  


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };
 
  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);


  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };


  

  return (
    <>
      <nav className="bg-white border-b border-gray-200 py-2">
        <div className="max-w-container mx-auto">
          <div className="mx-auto flex items-center justify-between py-3 "> 
            <div>
              <a href='/'> <img src={logo} alt="Logo" className="h-16" /></a>
            </div>  
            <div className="hidden space-x-2 text-[10px] xl:text-[15px] 2xl:text-base 3xl:text-[17px] 2xl:space-x-6  lg:flex"> 
              <a href="/" className="text-black text-base font-normal">{t("Əsas səhifə")}</a>
              <a href="/products" className="text-black text-base font-normal">{t("Məhsullar")}</a>
              <a href="/about" className="text-black text-base font-normal">{t("Haqqımızda")}</a>
              <a href="/services" className="text-black text-base font-normal">{t("Xidmətlər")}</a>
              <a href="/blogs" className="text-black text-base font-normal">{t("Bloq")}</a>
              <a href="/kataloq" className="text-black text-base font-normal">{t("Kataloq")}</a>
              <a href="/contact" className="text-black text-base font-normal">{t("Əlaqə")}</a>
            </div>
            <div>
            <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 px-4 py-2 border rounded-full border-[black]"
            >
              <span className="text-[#b7b7b7]">{selectedLanguage.label}</span>
            </button>

            {isOpen && (
              <div className="absolute right-0 top-10 mt-2 w-28 rounded-md shadow-lg bg-[white] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 text-[#b7b7b7] py-2 w-full text-left hover:bg-[#EDEDED]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
            </div>

          </div>
        </div>
      </nav>            
    </>
  ); 
};

export default Header;



