import React, { useEffect, useState } from "react";
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blog1, blog2, blog3, cardReader, dataTerminal, pulQutulari } from '../../assets/index';

export const products = [
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  { 
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  { 
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },

];

const Products = () => {
  // const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("default");
  const [brandFilters, setBrandFilters] = useState({});
  const [kateqoriyaFilters, setKateqoriyaFilters] = useState({});
  const [priceFilter, setPriceFilter] = useState("default");
  const [brandSearch, setBrandSearch] = useState("");
  const [kateqoriyaSearch, setKateqoriyaSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   getProducts();
  // }, []);

  // const getProducts = async () => {
  //   try {
  //     const response = await axios.get("https://www.kcollection.az/kcollection/dashboard.php/", {
  //       withCredentials: false,
  //       headers: {
  //         'Cache-Control': 'no-cache',
  //       },
  //     });
  //     console.log(response.data);
  //     setProducts(response.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBrandChange = (brand) => {
    setBrandFilters((prev) => ({
      ...prev,
      [brand]: !prev[brand],
    }));
  };

  const handleKateqoriyaChange = (kateqoriya) => {
    setKateqoriyaFilters((prev) => ({
      ...prev,
      [kateqoriya]: !prev[kateqoriya],
    }));
  };


  const handlePriceChange = (price) => {
    setPriceFilter(price);
  };

  const clearBrandFilters = () => {
    setBrandFilters({});
    setBrandSearch("");
  };
  const clearKateqoriyaFilters = () => {
    setKateqoriyaFilters({});
    setKateqoriyaSearch("");
  };

  // Filtering logic
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if brand filters are applied
    const hasSelectedBrands = Object.keys(brandFilters).some(key => brandFilters[key]);
    const matchesBrand = !hasSelectedBrands || brandFilters[product.brand];

        // Check if kateqoriya filters are applied
        const hasSelectedKateqoriya = Object.keys(kateqoriyaFilters).some(key => kateqoriyaFilters[key]);
        const matchesKateqoriya = !hasSelectedKateqoriya || kateqoriyaFilters[product.kateqoriya];



    return matchesSearch && matchesBrand && matchesKateqoriya;
  });

  // Sort products based on the selected option
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOption === "lowToHigh") {
      return a.price - b.price; // Sort ascending by price
    } else if (sortOption === "highToLow") {
      return b.price - a.price; // Sort descending by price
    }
    return 0; // No sorting
  });

  // Apply price filter
  const finalProducts = priceFilter === "default" ? sortedProducts : sortedProducts.filter(product => {
    if (priceFilter === "low") return product.price > 100 && product.price < 200; // Example: products priced below 50
    if (priceFilter === "high") return product.price > 200 && product.price < 300; // Example: products priced 50 and above
    if (priceFilter === "highh") return product.price > 300 && product.price < 400; // Example: products priced 50 and above
    if (priceFilter === "highhh") return product.price > 400 && product.price < 500; // Example: products priced 50 and above

    return true;
  });

  // Brands to display (for example purposes)
  const allBrands = [
    "Mefax", "Polino Bistech", "Ming gear", "Qliphor", "Linger",
    "Doowen"
  ];

   // Brands to display (for example purposes)
   const allKateqoriya = [
    "Kənd təsərrüfatı texnikası", "Aqreqatlar", "Tikinti texnikası", "Avtomobillər"
  ];

  // Filter brands based on search input
  const filteredBrands = allBrands.filter(brand =>
    brand.toLowerCase().includes(brandSearch.toLowerCase())
  );

   // Filter brands based on search input
   const filteredKateqoriya = allKateqoriya.filter(kateqoriya =>
    kateqoriya.toLowerCase().includes(kateqoriyaSearch.toLowerCase())
  );

  return (
    <>
      <div className='pb-[44px] bg-[white]'>
        <p className='text-4xl font-medium pt-16 text-center'>Məhsullar</p>
        
        <div className='flex max-w-container mx-auto mt-8 justify-between'>
          {/* Left Filter Section */}
          <div className="w-[209px]">
            <h4 className='text-black text-xl font-medium'>Filtrlər:</h4>

            
            <div className="mt-4">
             <div className="flex justify-between">
             <label className='text-black'>Kateqoriya:</label>
              <button
                  onClick={clearKateqoriyaFilters}
                  className="text-[#5b5b5b] rounded"
                >
                  Təmizlə
                </button>
             </div>
              <div className="flex flex-col overflow-y-scroll mt-2 max-h-48">
                {filteredKateqoriya.map((kateqoriya) => ( // Show only the first 3 brands
                  <div key={kateqoriya}>
                    <input
                      type="checkbox"
                      id={kateqoriya}
                      checked={!!kateqoriyaFilters[kateqoriya]}
                      onChange={() => handleKateqoriyaChange(kateqoriya)}
                    />
                    <label htmlFor={kateqoriya} className="ml-2 text-black">{kateqoriya.charAt(0).toUpperCase() + kateqoriya.slice(1)}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-4">
             <div className="flex justify-between">
             <label className='text-black'>İstehsalçı:</label>
              <button
                  onClick={clearBrandFilters}
                  className="text-[#5b5b5b] rounded"
                >
                  Təmizlə
                </button>
             </div>
              <div className="flex flex-col overflow-y-scroll mt-2 max-h-48">
                {filteredBrands.map((brand) => ( // Show only the first 3 brands
                  <div key={brand}>
                    <input
                      type="checkbox"
                      id={brand}
                      checked={!!brandFilters[brand]}
                      onChange={() => handleBrandChange(brand)}
                    />
                    <label htmlFor={brand} className="ml-2 text-black">{brand.charAt(0).toUpperCase() + brand.slice(1)}</label>
                  </div>
                ))}
              </div>
            </div>

          </div>

          {/* Products Section */}
          <div className="w-[853px]">
            {isLoading ?  <p className="text-[black]"> Loading...</p> :
              <PaginatedItems itemsPerPage={12} items={finalProducts} />  
            }
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;



















































