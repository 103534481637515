import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blog1, blog2, blog3, cardReader, dataTerminal, pulQutulari } from '../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },

];

const Blogs = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="mt-[40px] max-w-container mx-auto mb-[132px]">
         <div className='flex flex-col justify-center items-center mb-5'>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">Bloq</button>
         <p className="text-black text-[32px] font-medium text-center">Ən son bloqlarımız</p>
         </div>
        <div>
      <div className='grid grid-cols-3 gap-3'>
        {products.map((val, index) => {
          const { image, category, name, path, content} = val;
          return (
           <a href={path}>
             <div  data-aos="fade-up" className='bg-[#f5f9ee] rounded-[20px] pt-[1px]' key={index}>
              
              <div className='bg-[white] m-3 rounded-lg'>
              <img className='rounded-lg' src={image} alt={name} />
              </div>
              <div className='text m-3'>
                <h4 className='text-black text-xl font-medium'>{t(name)}</h4>
                <h4 className='text-[#b7b7b7] text-base font-normal'>{t(content)}</h4>
              </div>
            </div>
           </a>
          );
        })}
      </div>

        </div>
      </div>
  );
};

export default Blogs;