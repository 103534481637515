import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { hero, services1, services2, services3, services4, services5 } from "../../assets";

export default function Services() {

  const { t, i18n } = useTranslation();
  return (
    <>
       <div className="max-w-container mt-[40px] mx-auto mb-[132px]">
               <div className='flex flex-col justify-center items-center'>
         <button className="w-[120px] bg-[#d4d64f] rounded-[32px] text-[white]">Xidmətlər</button>
         <p className="text-black text-[32px] font-medium text-center">Ehtıyaclarınıza uyğun xıdmətlər</p>
         <p className="text-black text-base font-normal mt-[40px] mb-[60px]">AgroGurama kənd təsərrüfat şirkəti, aqreqatlarınızın uzun müddət və səmərəli işləməsini təmin etmək üçün mükəmməl texniki xidmət və təmir xidmətləri təqdim edir. Bizim mütəxəssislərimiz, avadanlıqlarınızın ən yaxşı vəziyyətdə qalmasını təmin etmək üçün aşağıdakı xidmətləri həyata keçirir:</p>
         </div>
  <div className="flex items-center mt-[40px]">
   <div className="w-[535px]"><img src={services1}></img></div>
<div className="ml-[40px]">
<p className="text-black text-[32px] font-medium mb-[40px]">Mütəmadi Texniki Baxış</p>
<p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
<p className="text-black text-base font-normal">- Avadanlığın vəziyyətinin müntəzəm yoxlanılması.  </p>
<p className="text-black text-base font-normal">- Yağ, filtr və digər istehlak materiallarının dəyişdirilməsi.</p>
<p className="text-black text-base font-normal">- Hissələrin aşınma səviyyəsinin qiymətləndirilməsi.</p>
</div>
   </div>
   <div className="flex justify-between items-center mt-[40px]">
   <div>
<p className="text-black text-[32px] font-medium mb-[40px]">Diaqnostika</p>
<p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
<p className="text-black text-base font-normal">- Avadanlığın iş prinsiplərini analiz edərək, potensial problemlərin erkən aşkarlanması. </p>
<p className="text-black text-base font-normal">- Müxtəlif diaqnostika alətləri ilə avadanlığın texniki vəziyyətinin qiymətləndirilməsi.</p>
</div>
<div className="w-[525px]"><img src={services2}></img></div>
   </div>
   <div className="flex items-center mt-[40px]">
   <div className="w-[535px]"><img src={services3}></img></div>
<div className="ml-[40px]">
<p className="text-black text-[32px] font-medium mb-[40px]">Təmir Xidməti</p>
<p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
<p className="text-black text-base font-normal">- Qüsurlu və ya aşınmış hissələrin dəyişdirilməsi.</p>
<p className="text-black text-base font-normal">- Avadanlığın elektrik sistemlərinin təmiri.</p>
<p className="text-black text-base font-normal">- Mexaniki və hidravlik sistemlərin bərpası.</p>
</div>
   </div>
   <div className="flex justify-between items-center mt-[40px]">
   <div>
<p className="text-black text-[32px] font-medium mb-[40px]">Avadanlıq Quraşdırılması</p>
<p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
<p className="text-black text-base font-normal">- Yeni aqreqatların mütəxəssislərimiz tərəfindən düzgün quraşdırılması.</p>
<p className="text-black text-base font-normal">- İstifadəçi üçün ətraflı təlimatların verilməsi.</p>
</div>
<div className="w-[525px]"><img src={services4}></img></div>
   </div>
   <div className="flex items-center mt-[40px]">
   <div className="w-[535px]"><img src={services5}></img></div>
<div className="ml-[40px]">
<p className="text-black text-[32px] font-medium mb-[40px]">Yedək Hissələrin Təchizatı</p>
<p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
<p className="text-black text-base font-normal">- Müxtəlif aqreqatlar üçün orijinal yedek hissələrin təmin edilməsi.</p>
<p className="text-black text-base font-normal">- Avadanlıqların düzgün işləməsi üçün lazım olan bütün hissələrin mövcudluğu.</p>
</div>
   </div>
     </div>
    </>
  );
}
